import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from '@reach/alert-dialog';

import { PopupProps } from './Popup.types';

import Button from '@common/Button/Button';
import styles from './Popup.module.scss';

function Popup(props: PopupProps) {
  const {
    label,
    description,
    setIsPopupOpen,
    redirectLink,
    cancelBtnText,
    acceptBtnText,
  } = props;

  const cancelRef = useRef<HTMLDivElement>(null);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      onDismiss={() => setIsPopupOpen(false)}
      ref={cancelRef}
      onClick={e => {
        if (e.target == cancelRef?.current) {
          setIsPopupOpen(false);
        }
      }}
    >
      <div className={styles.popupContainer}>
        <button
          className={styles.closePopup}
          aria-label="Close Popup"
          onClick={() => setIsPopupOpen(false)}
          onTouchEnd={() => setIsPopupOpen(false)}
        >
          <span id={styles.close}>
            <span />
          </span>
        </button>
        <div className={styles.popupContentWrapper}>
          <AlertDialogLabel className={styles.popupLabel}>
            {label}
          </AlertDialogLabel>
          <AlertDialogDescription className={styles.popupDescription}>
            {description}
          </AlertDialogDescription>

          <div className={styles.buttonContainer}>
            <div className={styles.buttonWrapper}>
              <Button
                as="button"
                design="round"
                className={styles.popupButton}
                onClick={() => setIsPopupOpen(false)}
              >
                {cancelBtnText}
              </Button>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                as="button"
                design="round"
                className={styles.popupButton}
                onClick={() => {
                  open(redirectLink);
                  setIsPopupOpen(false);
                }}
              >
                {acceptBtnText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AlertDialog>
  );
}

export default Popup;
